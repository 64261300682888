import request from '@/utils/request'


// 查询考生的考试列表
export function getTestList(userId) {
  return request({
    url: `/exam/exam/${userId}/exams`,
    method: 'get',
  })
}

//记录开始考试时间
export function recordTime(userId, examId) {
  return request({
    url: `/exam/exam/${userId}/${examId}/start`,
    method: 'get',
  })
}

// 查询考生的考试详情
export function getTestInfo(userId, examId) {
  return request({
    url: `/exam/exam/${userId}/${examId}`,
    method: 'get',
  })
}

// 查询考试成绩
export function getTestResult(userId, examId) {
  return request({
    url: `/exam/exam/${userId}/${examId}/score`,
    method: 'get'
  })
}

// 提交试卷
export function submitTest(params) {
  return request({
    url: '/exam/markingPaper/submit/marking',
    method: 'post',
    params: params
  })
}

//获取错题回顾列表
export function getWrongList(userId) {
  return request({
    url: `/exam/exam/${userId}/exams/wrongQues`,
    method: 'get',
  })
}

//获取试题解析
export function getTestAnalysis(userId,examId) {
  return request({
    url: `/exam/exam/${userId}/${examId}/analysis`,
    method: 'get',
  })
}