<template>
  <!-- 错题回顾 -->
  <div class="wrong">
    <my-header title="错题回顾"></my-header>
    <div class="list" v-for="(item, index) in list" :key="index">
      <p class="no">{{ index + 1 }}.</p>
      <p class="name">{{ item.examTitle }}</p>
      <p class="num">错题数量: {{ item.wrongQueCount }}</p>
      <div class="bottom" @click="goResult(item)">
        <span>题目解析</span>
        <img src="@/assets/image/icon/right.png" />
      </div>
    </div>
  </div>
</template>
<script>
import { getWrongList } from '@/api/my/test.js'
export default {
  data() {
    return {
      list: [],
    }
  },
  created() {
    let userid = this.$store.getters.user.id
    getWrongList(userid).then((res) => {
      console.log(res.result)
      this.list = res.result
    })
  },
  computed: {},
  methods: {
    goResult(item) {
      this.$router.push({
        path: '/test/result',
        query: { id: item.examId },
      })
    },
  },
}
</script>
<style lang="less" scoped>
.wrong {
  margin-top: 64px;
  padding: 0 4rem;
  .list {
    margin-top: 40px;
    position: relative;
    box-shadow: 2px 2px 9px 1px rgba(3, 3, 3, 0.05),
      -2px -2px 9px 1px rgba(3, 3, 3, 0.05);
    border-radius: 32px;
    padding: 48px 0 47px 6.125rem;
    .no {
      float: left;
      font-size: 54px;
      font-weight: 400;
      margin-left: -30px;
    }
    .name {
      font-size: 54px;
      font-weight: 400;
    }
    .num {
      margin-top: 80px;
      font-size: 40px;
      font-weight: 400;
      color: #666666;
    }
    .bottom {
      position: absolute;
      bottom: 48px;
      right: 48px;
      font-size: 40px;
      font-weight: 400;
      color: #ea5514;
      img {
        width: 2.25rem;
        vertical-align: middle;
        margin-left: 1rem;
      }
    }
  }
}
</style>
